import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Formik, Form, Field } from 'formik';
import { Grid, Container, Typography, TextField, Checkbox, MenuItem, Button, FormControl, InputLabel, Select } from '@mui/material';


// ----------------------------------------------------------------------
const ProvinciaSanJuan = [
  'San Juan',
  'Rivadavia',
  'Santa Lucía',
  'Rawson',
  'Pocito',
  'Caucete',
  'Capital',
  'Chimbas',
  '25 de Mayo',
  'Sarmiento',
];

const Categorias = [
  'Sueldo',
  'Combustible',
  'Varios',
];

const Rubros = [
  'Kiosco',
  'Almacén',
  'Verdulería',
  'Panadería',
  'Carnicería',
  'Zapatería',
  'Quiniela',
];

export default function BlankPage() {
  const theme = useTheme();

  const handleLocation = () => {
    // Lógica para generar ubicación
  };

  return (
    <>
      <Helmet>
        <title> Pagina de ejemplo </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Pagina de ejemplo
        </Typography>
        <Formik
          initialValues={{
            name: '',
            amount: '',
            dni: '',
            phone: '',
            address: '',
            message: '',
            status: false,
            fecha: '',
            zone: '',
            categoria: '',
            rubro: '',
          }}
          onSubmit={(values) => {
            // Lógica para enviar el formulario
          }}
        >
          {({ values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="name" label="Nombre" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="amount" label="Cantidad" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="dni" label="DNI" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="phone" label="Teléfono" type="number" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Field as={TextField} name="address" label="Dirección" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Field as={TextField} name="message" label="Mensaje" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Field as={Checkbox} name="status" label="Estado" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="fecha" label="Fecha" type="date" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="zone-select">Zona</InputLabel>
                    <Field as={Select} name="zone" label="Zona" fullWidth>
                      {ProvinciaSanJuan.map((zone) => (
                        <MenuItem key={zone} value={zone}>
                          {zone}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="categoria-select">Categorías</InputLabel>
                    <Field as={Select} name="categoria" label="Categorías" fullWidth>
                      {Categorias.map((categoria) => (
                        <MenuItem key={categoria} value={categoria}>{categoria}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="rubro-select">Rubros</InputLabel>
                    <Field as={Select} name="rubro" label="Rubros" fullWidth>
                      {Rubros.map((rubro) => (
                        <MenuItem key={rubro} value={rubro}>
                          {rubro}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
              </Grid>
              <Button type="submit" variant="contained" color="primary">
                Enviar
              </Button>
              <Button onClick={handleLocation} variant="outlined" color="secondary">
                Generar Ubicación
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
