import React, { useState, useEffect } from "react";
import firebase, { firebaseAuth, db } from "./firebase";

export const signUpWithEmailAndPassword = async (data) => {
  const { email, password, name } = data;
  const userRef = db.collection("/users");

  /* eslint-disable consistent-return */
  return new Promise((resolve, reject) => {
    if (!email || !password) {
      /* eslint-disable prefer-promise-reject-errors */
      return reject("Email o password erroneos");
    }
    // if (auth.currentUser) {
    //   return reject("A user is logged in. Please logout from dashboard first!");
    // }
    firebaseAuth.createUserWithEmailAndPassword(email, password)
      .then((value) => {
        const user = value.user;
        if (!user) {
          reject();

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject();

          return;
        }
        const person = {
          email: user.email,
          photoURL: user.photoURL,
          displayName: name,
          uid: user.uid,
        };
        if (name) {
          user.updateProfile({
            displayName: name,
          });
        }

        // Add user document.
        const userData = {
          role: 'collector',
          status: true,
          name
        };

        userRef.doc(user.uid).set(userData).then(() => { }).catch((error) => {
          console.error("Error al crear usuario: ", error);
        });

        // Add properties of user document.
        person.role = userData?.role
        resolve(person);
      })
      .catch((err) => {
        reject(err);
      });
  });
}