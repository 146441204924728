import { sample } from 'lodash';

// ----------------------------------------------------------------------

const listCuotas = [...Array(12)].map(() => ({
  cuotas: sample([ 'Cuota 1', 'Cuota 2','Cuota 3','Cuota 4', 'Cuota 5','Cuota 6','Cuota 7' , 'Cuota 8', 'Cuota 9','Cuota 10', 'Cuota 11', 'Cuota 12' ]),
  dateLimit: sample(['08/02/12', '22/09/12', '28/05/12', '30/10/12', '12/11/12']),
  amount: sample(['($500)', '($ 7000)', '($3000)','($6000)', '($5566)']),
  datePay: sample(['08/02/12', '22/09/12', '08/11/23 ']),
  hour: sample (['12:40', '17:23', '19:29', '20:15']),
}));

export default listCuotas;
