import { Helmet } from 'react-helmet-async';
import { filter, identity } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { db } from '../utils/firebase';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/prestamos';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'nroCuota', label: 'Nro Cuota', alignRight: false },
  { id: 'client', label: 'Cliente', alignRight: false },
  { id: 'amount', label: 'Monto', alignRight: false },
];

const dateSelected = new Date();
dateSelected.setHours(0, 0, 1, 0)
const unixTimestamp = Math.floor(dateSelected.getTime() / 1000); // convert to seconds.

const dateEndSelected = new Date();
dateEndSelected.setHours(23, 59, 59, 0)
const unixEndTimestamp = Math.floor(dateEndSelected.getTime() / 1000); // convert to seconds.

export default function DaysBox() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [credits, setCredits] = useState([]);
  const [totalCredits, setTotalCredits] = useState(0);


  useEffect(() => {
    fetchCredits();
  }, []);

  const fetchCredits = () => {
    db.collection('credits').where("status", "==", "active")
      // .where("dateTimestamp", ">=", values.fromDateTimestamp)
      // .where("dateTimestamp", "<=", values.tomDateTimestamp)
      .get().then((querySnapshot) => {
        const pays = [];
        let total = 0;
        querySnapshot.forEach((doc) => {
          doc.data().payments.forEach((pay, i) => {
            if (pay.status &&
              pay.datePay >= unixTimestamp &&
              pay.datePay <= unixEndTimestamp) {
              const newPago = {
                client: doc.data().client.name,
                credit: doc.id,
                datePay: pay.datePay,
                nroCuota: i + 1,
                amount: pay.amount
              };
              pays.push(newPago);
              total += +(pay.amount);
            }
          })
          setCredits(pays);
          setTotalCredits(total);
        });
      })
      .catch((error) => {
        console.log('Error al obtener un cliente: ', error);
      });
  };

  const goTo = (data) => {
    navigate('/dashboard/daysBoxTable', {
      state: data
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  console.log(credits);

  return (
    <>
      <Helmet>
        <title>Caja Diaria</title>
      </Helmet>

      <Container>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Caja Diaria
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={goTo}>
            Generar caja diaria
          </Button>
        </Stack>
      </Container>
      <Container>
        <Stack direction="column" mb={5}>
          <Card mb={5}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {credits.map((row, k) => {
                      const { credit, nroCuota, client, user, amount } = row;
                      const selectedMovement = selected.indexOf(user) !== -1;

                      return (
                        <TableRow hover key={k} tabIndex={-1} role="checkbox" selected={selectedMovement}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedMovement} onChange={(event) => handleClick(event, user)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {nroCuota}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{client} ({credit})</TableCell>
                          <TableCell align="left">{amount}</TableCell>
                        </TableRow>
                      );
                    })}
                    {credits.length > 0 && (
                      <TableRow style={{ height: 53 * credits.length }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {credits.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              No hay registros
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  <TableRow color="success" sx={{ backgroundColor: '#e0f2f1' }}>
                    <TableCell align="center" colSpan={2} color="success">
                      Total: $ {totalCredits}
                    </TableCell>
                    <TableCell align="center" colSpan={5}>
                      <Button
                        type="submit"
                        sx={{
                          backgroundColor: '#43a047',
                          color: 'white',
                          ':hover': {
                            color: 'black',
                          },
                        }}
                      >
                        Guardar
                      </Button>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Stack>
      </Container>
    </>
  );
}
