import { useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useDispatch } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import Resumen from './pages/Resumen';
import AddUser from './pages/AddUser';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import BlankPage from './pages/BlankPage';
import AddCredit from './pages/AddCredit';
import AddClient from './pages/AddClient';
import Clients from './pages/Clients';
import Prestamos from './pages/Credits';
import AddMovements from './pages/AddMovement';
import Movement from './pages/Movement';
import UsersPage from './pages/UsersPage';
import ClientDetail from './pages/ClientDetail';
import CreditDetail from './pages/CreditDetail';
import { db, firebaseObserver, loggedIn } from './utils/firebase';
import allActions from './state/actions';
import Home from './pages/Home';
import DaysBox from './pages/DaysBox';
import DayBoxTable from './pages/DayBoxTable'

export default function Router() {
  const [authenticated, setAuthenticated] = useState(loggedIn());
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState(null);
  const dispatch = useDispatch()


  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', data => {
      setAuthenticated(data);
      const fetchData = async () => {
        const roleData = (await db.collection('users').doc(data?.uid).get()).data();
        setRole(roleData);
        dispatch(allActions.userActions.setUser({ ...roleData, uid: data?.uid }));
      }
      fetchData();
      setIsLoading(false);
    });
    return () => { firebaseObserver.unsubscribe('authStateChanged'); }
  }, []);

  useEffect(() => {
    if (role) {
      setIsLoading(false)
    }
  }, [role])


  const pages = useRoutes(routes(authenticated, role))
  return isLoading ? <div /> : <>{pages}</>;
}

const routes = (isAuth, user) => {
  const role = user?.role;

  const publicRoutes = [
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: isAuth ? <Navigate to="/dashboard/credits" /> : <LoginPage />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    }
  ];

  const authRoutes = [
    {
      path: '/dashboard',
      element: <DashboardLayout user={user} />,
      children: [
        { element: <Navigate to="/dashboard/Home" />, index: true },
        { path: 'app', element: <Home /> },

        { path: 'users', element: isAuth && role === 'admin' ? <UsersPage /> : <LoginPage /> },
        { path: 'users/add', element: isAuth && role === 'admin' ? <AddUser /> : <LoginPage /> },
        { path: 'credits', element: isAuth && (role === 'collector' || role === 'admin') ? <Prestamos /> : <LoginPage /> },
        { path: 'credits/add', element: isAuth && role === 'admin' ? <AddCredit /> : <LoginPage /> },
        { path: 'clients', element: isAuth && role === 'admin' && <Clients /> },
        { path: 'clients/add', element: isAuth && role === 'admin' ? <AddClient /> : <LoginPage /> },
        { path: 'clients/detail', element: isAuth && <ClientDetail /> },
        { path: 'movements', element: isAuth && role === 'admin' ? <Movement /> : <LoginPage /> },
        { path: 'movements/add', element: isAuth && role === 'admin' ? <AddMovements /> : <LoginPage /> },
        { path: 'resumen', element: <Resumen /> },
        { path: 'credit-detail', element: isAuth && (role === 'collector' || role === 'admin') ? <CreditDetail /> : <LoginPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'daysBox', element: isAuth && (role === 'collector' || role === 'admin') ? <DaysBox /> : <LoginPage /> },
        { path: 'daysBoxTable', element: isAuth && (role === 'collector' || role === 'admin') ? <DayBoxTable /> : <LoginPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    }
  ];
  return [...publicRoutes, ...authRoutes];
}
