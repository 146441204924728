import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Formik, Form, Field } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Grid,
  Container,
  Typography,
  TextField,
  Checkbox,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  Alert,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { addClient, updateClient } from '../utils/services';
import { cities, rubros } from '../utils/data';

// ----------------------------------------------------------------------

export default function AddClient() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { state } = useLocation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clientList();
  };
  const clientList = () => {
    navigate('/dashboard/clients')
  }


  const handleSubmit = async (data) => {
    try {
      if (!state) {
        await addClient(data);
      } else {
        await updateClient(data);
      }
      setOpen(true);
      setTimeout(() => {
        navigate('/dashboard/clients', { replace: true });
      }, 4000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleLocation = () => {
    // Lógica para generar ubicación
  };

  console.log(state);

  return (
    <>
      <Helmet>
        <title> Agregar / Editar un Cliente</title>
      </Helmet>

      <Container maxWidth="xl">
        <Formik
          initialValues={{
            name: state ? state.name : '',
            dni: state ? state.dni : '',
            phone: state ? state.phone : '',
            address: state ? state.address : '',
            status: state ? state.status : true,
            city: state ? state.city : '',
            rubro: state ? state.rubro : '',
            keywords: state ? state.keywords : [],
          }}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="name" label="Nombre" fullWidth value={values.name} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="dni" label="DNI" type="number" fullWidth value={values.dni} />
                </Grid>
                <Grid item xs={12}>
                  <Field as={TextField} name="phone" type="number" label="Teléfono" fullWidth value={values.phone} />
                </Grid>
                <Grid item xs={12}>
                  <Field as={TextField} name="address" label="Dirección" fullWidth value={values.address} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="zone-select">Zona</InputLabel>
                    <Field as={Select} name="city" label="Zona" fullWidth value={values.city}>
                      {cities.map((city) => (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="rubro-select">Rubros</InputLabel>
                    <Field as={Select} name="rubro" label="Rubros" fullWidth value={values.rubro}>
                      {rubros.map((rubro) => (
                        <MenuItem key={rubro.id} value={rubro.id}>
                          {rubro.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Button type="submit" variant="contained" color="primary" onClick={handleOpen}>
                    Crear
                  </Button>
                  <Grid>
                    <Dialog open={open} onClose={handleClose} maxWidth="lg">
                      <DialogTitle sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                          <CheckCircleOutlineTwoToneIcon
                            color="success"
                            style={{ fontSize: '50px', marginLeft: '12px' }}
                          />
                        </IconButton>
                        <Typography variant="h6" style={{ fontSize: '20px' }}>
                          El cliente fue {state ? 'actualizado' : 'creado'} correctamente!
                        </Typography>
                      </DialogTitle>
                      <DialogActions sx={{ alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                        <Button onClick={handleClose} variant="outlined" color="error">
                          Cerrar
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
                {/* <Grid item xs={6}>
                  <Button onClick={handleLocation} variant="outlined" color="secondary">
                    Generar Ubicación
                  </Button>
                </Grid> */}
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
