// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Creditos',
    path: '/dashboard/credits',
    icon: icon('ic_user'),
  },
  {
    title: 'usuarios',
    path: '/dashboard/users',
    icon: icon('ic_user'),
  },
  {
    title: 'Clientes',
    path: '/dashboard/clients',
    icon: icon('ic_user'),
  },
  {
    title: 'product',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'resumen',
    path: '/dashboard/resumen',
    icon: icon('ic_blog'),
  },
  {
    title: 'Movimientos',
    path: '/dashboard/movements',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Caja diaria',
    path: '/dashboard/DaysBox',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'alertas',
  //   path: '/alertas',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

const navCollector = [
  {
    title: 'Creditos',
    path: '/dashboard/credits',
    icon: icon('ic_user'),
  },
];

export { navConfig, navCollector };

