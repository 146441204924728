import { Helmet } from 'react-helmet-async';
// @mui
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import {
  Grid,
  Container,
  Typography,
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  Alert,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddMovement } from '../utils/services';
import { categoryMovements, typeMovements } from '../utils/data';

export default function AddMovements() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const validate = useState(false);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    movementList();
  };
  const movementList = () => {
    navigate('/dashboard/movements')
  }

  const handleSubmit = async (data) => {
    try {
      const movements = await AddMovement(data);
      setOpen(true);
      setTimeout(() => {
        navigate('/dashboard/movements', { replace: true });
      }, 4000);
    } catch (err) {
      console.log(err);
    }
    /*  Validacion por Formik  */

    const validate = (values) => {
      console.log(values);
      const errors = {};

      if (!values.amount) {
        errors.amount = 'El monto es requerido';
      }

      if (!values.message) {
        errors.message = 'El mensaje es requerido';
      }

      if (!values.dateTimestamp) {
        errors.date = 'La fecha es requerida';
      }

      if (!values.category) {
        errors.category = 'La categoría es requerida';
      }

      if (!values.type) {
        errors.type = 'El tipo es requerido';
      }

      if (!values.user) {
        errors.user = 'El usuario es requerido';
      }

      return errors;
    };


  };

  return (
    <>
      <Helmet>
        <title> Agregar un Moviemiento</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Para iniciar un moviemiento debes completar los siguientes campos:
        </Typography>
        <Formik
          initialValues={{
            amount: '',
            message: '',
            date: null,
            dateTimestamp: null,
            category: '',
            type: '',
            user: '',
          }}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {console.log(values)}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="amount" type="number" label="Monto" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="zone-select">Tipo</InputLabel>
                    <Field as={Select} name="type" label="Tipo" fullWidth>
                      {typeMovements.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="categoria-select">Categoría</InputLabel>
                    <Field as={Select} name="category" label="Categoría" fullWidth>
                      {categoryMovements.map((categoryMovements) => (
                        <MenuItem key={categoryMovements.id} value={categoryMovements.id}>
                          {categoryMovements.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="message"
                    label="Mensaje"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={(value) => {
                        const dateSelected = new Date(value);
                        setFieldValue(
                          'date',
                          dateSelected.toLocaleDateString('es-AR', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })
                        );
                        const unixTimestamp = Math.floor(dateSelected.getTime() / 1000); // convert to seconds.
                        setFieldValue('dateTimestamp', unixTimestamp);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="user" label="Usuario" fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" onClick={handleOpen}>
                    Crear
                  </Button>
                  <Grid>
                    <Dialog open={open} onClose={handleClose} maxWidth="lg">
                      <DialogTitle sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                          <CheckCircleOutlineTwoToneIcon
                            color="success"
                            style={{ fontSize: '50px', marginLeft: '12px' }}
                          />
                        </IconButton>
                        <Typography variant="h6" style={{ fontSize: '20px' }}>
                          El movimiento fue creado correctamente!
                        </Typography>
                      </DialogTitle>
                      <DialogActions sx={{ alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                        <Button onClick={handleClose} variant="outlined" color="error">
                          Cerrar
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
