import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import { db } from '../utils/firebase';
import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'message', label: 'Mensaje', alignRight: false },
  { id: 'amount', label: 'Monto', alignRight: false },
  { id: ' ' },

];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DaysBoxTable() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [movements, setMovements] = useState([]);
  const [credit, setCredit] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);


  useEffect(() => {
    fetchMovements();
  }, []);

  const fetchMovements = () => {
    const movementsArray = [];

    // Get movimientos.
    db.collection('movements')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          movementsArray.push(doc.data());
        });
        setMovements(movementsArray);
      })
      .catch((error) => {
        console.log('Error al obtener movimiento: ', error);
      });
  };

  useEffect(() => {
    fetchCredits();
  }, []);

  const fetchCredits = () => {
    const creditsArray = [];

    // Get movimientos.
    db.collection('credits')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          creditsArray.push(doc.data());
        });
        setCredit(creditsArray);
      })
      .catch((error) => {
        console.log('Error al obtener movimiento: ', error);
      });
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredMovements = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredMovements.length && !!filterName;

  const goToMovements = () => {
    navigate('/dashboard/movements/add');
  };

  console.log(movements, 'ingreso el dato');

  return (
    <>
      <Helmet>
        <title> Seccion Movimientos</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Caja diaria
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {movements.map((row) => {
                    const { id, message, user, amount } = row;
                    const selectedMovement = selected.indexOf(user) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedMovement}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedMovement} onChange={(event) => handleClick(event, user)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {id}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{message}</TableCell>
                        <TableCell align="left">{amount}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                  <TableRow color="success" sx={{ backgroundColor: '#e0f2f1' }}>
                  <TableCell align="center" colSpan={2} color="success">
                    Total: $ 0
                  </TableCell>
                  <TableCell align="center" colSpan={2} color="success">
                    Falta: $ 
                  </TableCell>
                  <TableCell align="center" colSpan={5}>
                    <Button
                      type="submit"
                      sx={{
                        backgroundColor: '#43a047',
                        color: 'white',
                        ':hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Guardar
                    </Button>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <br />

        <Card>
          <h2 style={{textAlign: 'center'}}>Ingreso</h2>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {movements.map((row) => {
                    const { id, message, user, amount } = row;
                    const selectedMovement = selected.indexOf(user) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedMovement}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedMovement} onChange={(event) => handleClick(event, user)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {id}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{message}</TableCell>
                        <TableCell align="left">{amount}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableRow color="success" sx={{ backgroundColor: '#e0f2f1' }}>
                  <TableCell align="center" colSpan={2} color="success">
                    Total: $ 0
                  </TableCell>
                  <TableCell align="center" colSpan={2} color="success">
                    Falta: $ 
                  </TableCell>
                  <TableCell align="center" colSpan={5}>
                    <Button
                      type="submit"
                      sx={{
                        backgroundColor: '#43a047',
                        color: 'white',
                        ':hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Guardar
                    </Button>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <br />

        <Card>
          <h2 style={{textAlign: 'center'}}>Egreso</h2>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {movements.map((row) => {
                    const { id, message, user, amount } = row;
                    const selectedMovement = selected.indexOf(user) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedMovement}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedMovement} onChange={(event) => handleClick(event, user)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {id}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{message}</TableCell>
                        <TableCell align="left">{amount}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableRow color="success" sx={{ backgroundColor: '#e0f2f1' }}>
                  <TableCell align="center" colSpan={2} color="success">
                    Total: $ 0
                  </TableCell>
                  <TableCell align="center" colSpan={2} color="success">
                    Falta: $ 
                  </TableCell>
                  <TableCell align="center" colSpan={5}>
                    <Button
                      type="submit"
                      sx={{
                        backgroundColor: '#43a047',
                        color: 'white',
                        ':hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Guardar
                    </Button>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
