import { db } from "./firebase";

export const addClient = async (data) => {
    const clientRef = db.collection("/clients");
    data.keywords = [...data.name.toLowerCase().split(' '), data.dni];
    await clientRef.add(data);
}

export const updateClient = async (data) => {
    const clientRef = db.collection("/clients");
    data.keywords = [...data.name.toLowerCase().split(' '), data.dni];
    await clientRef.doc(data.id).set(data).then(() => { });
}

export const AddMovement = async (data) => {
    const MovementRef = db.collection("/movements");
    await MovementRef.add(data);
}
