import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { Formik, Form, Field } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Grid,
  Container,
  Typography,
  Button,
  Switch,
  Checkbox,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stack,
} from '@mui/material';
import Scrollbar from '../components/scrollbar';
import { db } from '../utils/firebase';
import { UserListHead } from '../sections/@dashboard/user';

const TABLE_HEAD = [
  { id: 'credit', label: 'Credito', alignRight: false },
  { id: 'client', label: 'Cliente', alignRight: false },
  { id: 'nroCuota', label: 'Nro Cuota', alignRight: false },
  { id: 'amount', label: 'Monto', alignRight: false },
];

export default function Resumen() {
  const [data, setData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);


  const handleLocation = () => {
    // Lógica para generar ubicación
  };

  const handleSubmit = async (values) => {
    db.collection('credits').where("status", "==", "active")
      // .where("dateTimestamp", ">=", values.fromDateTimestamp)
      // .where("dateTimestamp", "<=", values.tomDateTimestamp)
      .get().then((querySnapshot) => {
        const pays = [];
        let total = 0;
        querySnapshot.forEach((doc) => {
          doc.data().payments.forEach((pay, i) => {
            if (!pay.status &&
              pay.datePay >= values.fromDateTimestamp &&
              pay.datePay <= values.toDateTimestamp) {
              const newPago = {
                client: doc.data().client.name,
                credit: doc.id,
                datePay: pay.datePay,
                nroCuota: i + 1,
                amount: pay.amount
              };
              pays.push(newPago);
              total += +(pay.amount.toString().replace(',', '').replace('.', ''));
            }
          })
          setData(pays);
          setTotalAmount(total);
        });
      })
      .catch((error) => {
        console.log('Error al obtener un cliente: ', error);
      });
  }

  return (
    <>
      <Helmet>
        <title> Controlar </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Para verificar un resumen debes completar los siguientes campos:
        </Typography>
        <Formik
          initialValues={{
            type: 'por_cobrar',
            fromDate: null,
            fromDateTimestamp: null,
            toDate: null,
            toDateTimestamp: null
          }}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="Desde" onChange={(value) => {
                      const dateSelected = new Date(value);
                      dateSelected.setHours(1, 0, 1, 0)
                      setFieldValue("fromDate", dateSelected.toLocaleDateString('es-AR', { year: 'numeric', month: '2-digit', day: '2-digit' }))
                      const unixTimestamp = Math.floor(dateSelected.getTime() / 1000); // convert to seconds.
                      setFieldValue("fromDateTimestamp", unixTimestamp)
                    }} />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="Hasta" onChange={(value) => {
                      const dateSelected = new Date(value);
                      dateSelected.setHours(23, 59, 59, 0)
                      setFieldValue("toDate", dateSelected.toLocaleDateString('es-AR', { year: 'numeric', month: '2-digit', day: '2-digit' }))
                      const unixTimestamp = Math.floor(dateSelected.getTime() / 1000); // convert to seconds.
                      setFieldValue("toDateTimestamp", unixTimestamp)
                    }} />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                />
                <TableBody>
                  {data.map((row) => {
                    const { credit, client, nroCuota, amount } = row;
                    return (
                      <TableRow hover key={client} tabIndex={-1} >
                        <TableCell padding="checkbox">
                          -
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {credit}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{client}</TableCell>
                        <TableCell align="left">{nroCuota}</TableCell>
                        <TableCell align="left">{amount}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                <TableRow color="success" sx={{ backgroundColor: '#e0f2f1' }}>
                  <TableCell align="center" colSpan={2} color="success">
                    Total: $ {totalAmount}
                  </TableCell>
                  <TableCell align="center" colSpan={5}>
                    <Button
                      type="submit"
                      sx={{
                        backgroundColor: '#43a047',
                        color: 'white',
                        ':hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Guardar
                    </Button>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
