import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import {
  Grid,
  Container,
  Typography,
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
} from '@mui/material';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useNavigate } from 'react-router-dom';
import { db } from '../utils/firebase';
import { rubros } from '../utils/data';
import { AddMovement } from '../utils/services';

const payForms = [
  {
    id: 'none',
    name: 'Diario',
  },
  {
    id: '1',
    name: 'Semanal / Lunes',
  },
  {
    id: '2',
    name: 'Semanal / Martes',
  },
  {
    id: '3',
    name: 'Semanal / Miercoles',
  },
  {
    id: '4',
    name: 'Semanal / Jueves',
  },
  {
    id: '5',
    name: 'Semanal / Viernes',
  },
  {
    id: '6',
    name: 'Semanal / Sabados',
  },
];

const curentDate = new Date();
const createdAt = Math.floor(curentDate.getTime() / 1000);

export default function AddCredit() {
  const fechaEntrega = parseInt(new Date().getTime() / 1000, 10);
  const [clients, setClients] = useState([]);
  const [collectors, setCollectors] = useState([]);
  const [collector, setCollector] = useState(null);

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    creditList();
  };
  const creditList = () => {
    navigate('/dashboard/credits')
  }


  useEffect(() => {
    fetchCollectors();
  }, []);

  const fetchCollectors = () => {
    // Get clients
    const collectorsArray = [];
    db.collection('users')
      .where('role', '==', 'collector')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          collectorsArray.push({ ...doc.data(), id: doc.id });
        });
        setCollectors(collectorsArray);
      })
      .catch((error) => {
        console.log('Error al obtener un cliente: ', error);
      });
  };

  const handleSave = async (data) => {
    let pays;
    if (data.byWeek === 'none') {
      pays = generatePayments(data);
    } else {
      pays = generateByWeek(data);
    }

    const { payments, endTimestamp, endFormat } = pays;

    let idCredit = 0;
    db.collection('credits')
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          idCredit = 1;
        } else {
          // More of a client.
          idCredit = parseInt(querySnapshot.docs[0].id, 10) + 1;
        }

        db.collection('credits')
          .doc(idCredit.toString())
          .set({
            ...data,
            id: idCredit.toString(),
            payments,
            collector,
            endTimestamp,
            endFormat,
          })
          .then(() => {
            handleOpen()
          })
          .catch((error) => {
          });
      })
      .catch((error) => {
        console.log('Error al crear credito.', error);
      });

    await AddMovement({
      amount: data.amount,
      category: 'credito_entregado',
      message: `Credito entregado a ${data.client.name}`,
      type: 'entrada',
      user: ''
    });
  };

  const generateByWeek = (data) => {
    const { startDate, nroCuotas, amountDiary, byWeek } = data;

    const payments = [];
    let endTimestamp = null;
    let endFormat = null;

    // Every days.
    // const every = byWeek ? 7 : 1;
    let nextDayPay = startDate;
    for (let i = 1; i <= nroCuotas; i += 1) {
      nextDayPay = getNextPay(nextDayPay, byWeek);
      const timestamp = Math.floor(nextDayPay.getTime() / 1000);
      const dateFormat = nextDayPay.toLocaleDateString('es-AR', { year: 'numeric', month: '2-digit', day: '2-digit' });

      payments.push({
        datePay: timestamp,
        datePayFormat: dateFormat,
        amount: amountDiary,
        status: false
      });

      endTimestamp = timestamp;
      endFormat = dateFormat
    }

    return { payments, endTimestamp, endFormat };
  }

  const generatePayments = (data) => {
    const { amountDiary, startDate, cuotasPaid } = data;
    let { nroCuotas } = data;

    const payments = [];
    let endTimestamp = null;
    let endFormat = null;

    for (let i = (1 - cuotasPaid); i <= (nroCuotas - cuotasPaid); i += 1) {
      const newPay = {
        status: false,
        datePay: null,
        datePayOk: null,
      };

      // Current day in timestamp.
      const dayInSeconds = 60 * 60 * 24 * (i - 1);
      const fechaPagoTimestamp = Math.floor(startDate.getTime() / 1000) + parseInt(dayInSeconds, 10);
      const fechaDate = new Date(fechaPagoTimestamp * 1000);
      const dateFormat = fechaDate.toLocaleDateString('es-AR', { year: 'numeric', month: '2-digit', day: '2-digit' });

      if (fechaDate.getDay() === 0 && i > 0) {
        // If day is sunday(0) =  plus 1.
        nroCuotas += 1;
      } else {
        newPay.datePay = fechaPagoTimestamp;
        newPay.datePayFormat = dateFormat;
        newPay.amount = amountDiary;
        if (i <= 0) {
          newPay.status = true;
        }

        payments.push(newPay);

        // Save last timestamp and endFormat.
        endTimestamp = fechaPagoTimestamp;
        endFormat = dateFormat
      }
    }

    return { payments, endTimestamp, endFormat };
  };

  const fetchClients = (value) => {
    if (value.length > 2) {
      db.collection('clients')
        .where('keywords', 'array-contains', value)
        .limit(8)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              setClients((prev) => [...prev, { ...doc.data(), id: doc.id }]);
            });
          }
        })
        .catch((error) => {
          console.log('Error al buscar cliente.', error);
        });
    }
  };

  const getNextPay = (date = new Date(), day) => {
    const dateCopy = new Date(date.getTime());

    const nextWeek = new Date(
      dateCopy.setDate(
        dateCopy.getDate() + ((7 - dateCopy.getDay() + +day) % 7 || 7),
      ),
    );

    return nextWeek;
  };

  const SignupSchema = Yup.object().shape({
    amount: Yup.number().required('El monto total es requerido.'),
    amountDiary: Yup.number().required('El monto diario es requerido.'),
    collector: Yup.string().required('Debe seleccionar un cobrador.'),
    nroCuotas: Yup.number().required('El numero de cuotas es requerido.'),
  });

  return (
    <>
      <Helmet>
        <title> Agregar un Moviemiento</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Para iniciar un prestamo debes completar los siguientes campos:
        </Typography>
        <Formik
          initialValues={{
            name: '',
            amount: null,
            dni: '',
            phone: '',
            address: '',
            message: '',
            status: 'active',
            zone: '',
            product: '',
            rubro: '',
            collector: null,
            nroCuotas: null,
            amountDiary: '',
            cuotasPaid: 0,
            lastPayDate: null,
            lastPayDateTimestamp: null,
            client: {
              id: null,
              name: '',
            },
            date: null,
            dateTimestamp: null,
            byWeek: 'none',
            createdAt
          }}
          validationSchema={SignupSchema}
          onSubmit={handleSave}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      setFieldValue('client', { id: newValue.id, name: newValue.name });
                      setFieldValue('name', newValue.name);
                      setFieldValue('dni', newValue.dni);
                      setFieldValue('phone', newValue.phone);
                      setFieldValue('address', newValue.address);
                      setFieldValue('rubro', newValue.rubro);
                    }}
                    onInputChange={(event, value) => {
                      fetchClients(value);
                    }}
                    disablePortal
                    id="combo-box-demo"
                    options={clients}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Cliente" />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="dni" label="DNI" fullWidth />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="name" label="Nombre" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="phone" label="Teléfono" type="number" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Field as={TextField} name="address" label="Dirección" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="rubro-select">Rubros</InputLabel>
                    <Field as={Select} name="rubro" label="Rubros" fullWidth>
                      {rubros.map((rubro) => (
                        <MenuItem key={rubro.id} value={rubro.id}>
                          {rubro.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="categoria-select">Cobrador</InputLabel>
                    <Field as={Select} name="collector" label="Cobrador" fullWidth>
                      {collectors.map((collector) => (
                        <MenuItem key={collector.id} value={collector.name} onClick={() => setCollector({ id: collector.id, name: collector.name })}>
                          {collector.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  {errors.collector && touched.collector ? (
                    <div style={{ color: "red" }}>{errors.collector}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field as={TextField} name="amount" label="Monto Total" type="number" fullWidth />
                  {errors.amount && touched.amount ? (
                    <div style={{ color: "red" }}>{errors.amount}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field as={TextField} name="nroCuotas" label="Cantidad de cuotas" type="number" fullWidth />
                  {errors.nroCuotas && touched.nroCuotas ? (
                    <div style={{ color: "red" }}>{errors.nroCuotas}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="categoria-select">Forma de pago</InputLabel>
                    <Field as={Select} name="byWeek" label="Forma de pago" fullWidth>
                      {payForms.map((collector) => (
                        <MenuItem key={collector.id} value={collector.id}>
                          {collector.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <Field as={TextField} name="cuotasPaid" label="Cuotas pagas" type="number" fullWidth />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="amountDiary" label="Monto de cuota" fullWidth />
                  {errors.amountDiary && touched.amountDiary ? (
                    <div style={{ color: "red" }}>{errors.amountDiary}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="product" label="Producto/Servicio" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={(value) => {
                        const dateSelected = new Date(value);
                        setFieldValue('startDate', dateSelected)
                        setFieldValue(
                          'date',
                          dateSelected.toLocaleDateString('es-AR', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })
                        );
                        const unixTimestamp = Math.floor(dateSelected.getTime() / 1000); // convert to seconds.
                        setFieldValue('dateTimestamp', unixTimestamp);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Crear
                  </Button>
                  <Grid>
                    <Dialog open={open} onClose={handleClose} maxWidth="lg">
                      <DialogTitle sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                          <CheckCircleOutlineTwoToneIcon color="success" style={{ fontSize: '50px', marginLeft: '12px' }} />
                        </IconButton>
                        <Typography variant="h6" style={{ fontSize: '20px' }}>
                          El prestamo fue creado correctamente!
                        </Typography>
                      </DialogTitle>
                      <DialogActions sx={{ alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                        <Button onClick={handleClose} variant="outlined" color="error">
                          Cerrar
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
