import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { db, myFirebase } from '../../../utils/firebase';
import allActions from '../../../state/actions';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const onLogin = () => {
    // Login to firebase.
    myFirebase.auth().signInWithEmailAndPassword(email, password)
      .then((response) => {
        // Get profile.
        console.log(response.user.uid);
        db.collection("users").doc(response.user.uid).get().then((doc) => {
          if (doc.exists) {
            console.log({ ...doc.data(), uid: response.user.uid });
            dispatch(allActions.userActions.setUser({ ...doc.data(), uid: response.user.uid }));
            navigate('/dashboard/credits', { replace: true });
          } else {
            // doc.data() will be undefined in this case
            console.log("No se pudo obtener el perfil.");
          }
        }).catch((error) => {
          console.log("Error al obtener el documento:", error);
        });
      })
      .catch(error => {
      });
  }


  const handleClick = () => {
    navigate('/dashboard', { replace: true });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email" onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Olvido su password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => onLogin()}>
        Ingresar
      </LoadingButton>
    </>
  );
}
