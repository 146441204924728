export const rubros = [
  {
    id: 'kiosco',
    name: 'Kiosco'
  },
  {
    id: 'almacen',
    name: 'Almacen'
  },
  {
    id: 'verduleria',
    name: 'Verduleria'
  },
  {
    id: 'panaderia',
    name: 'Panaderia'
  },
  {
    id: 'carniceria',
    name: 'Carniceria'
  },
  {
    id: 'zapateria',
    name: 'Zapateria'
  },
  {
    id: 'quiniela',
    name: 'Quiniela'
  }
];

export const cities = [
  {
    id: "capital",
    name: "Capital"
  },
  {
    id: "rivadavia",
    name: "Rivadavia"
  },
  {
    id: "santalucia",
    name: "Santa Lucia"
  },
  {
    id: "rawson",
    name: "Rawson"
  },
  {
    id: "pocito",
    name: "Pocito"
  },
  {
    id: "chimbas",
    name: "Chimbas"
  },
  {
    id: "caucete",
    name: "Caucete"
  },
  {
    id: "25demayo",
    name: "25 de Mayo"
  },
  {
    id: "sarmiento",
    name: "Sarmiento"
  }
];

export const typeMovements = [
  { id: 'entrada', name: 'Ingreso de capital' },
  { id: 'salida', name: 'Egreso de capital' }
];

export const categoryMovements = [
  { id: 'sueldo', name: 'Sueldo' },
  { id: 'combustible', name: 'Combustible' },
  { id: 'capital', name: 'Capital' },
  { id: 'credito_entregado', name: 'Credito entregado' },
  { id: 'otros', name: 'Otros' },
];

export const cuotas = [
  { id: 'cuota1', name: 'Cuota 1' },
  { id: 'cuota2', name: 'Cuota 2' },
  { id: 'cuota3', name: 'Cuota 3' },
  { id: 'cuota4', name: 'Cuota 4' },
  { id: 'cuota5', name: 'Cuota 5' },
  { id: 'cuota6', name: 'Cuota 6' },
  { id: 'cuota7', name: 'Cuota 7' },
  { id: 'cuota8', name: 'Cuota 8' },
  { id: 'cuota9', name: 'Cuota 9' },
  { id: 'cuota10', name: 'Cuota 10' },
  { id: 'cuota11', name: 'Cuota 11' },
  { id: 'cuota12', name: 'Cuota 12' },
];

export const status = [
  { id: 'active', name: 'Activo' },
  { id: 'banned', name: 'Inactivo' },
]
