import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import moment from 'moment';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Input,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Alert,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
// components
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import LISTCUOTAS from '../_mock/listCuotas';
import { db } from '../utils/firebase';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'coutas', label: 'Cuota', alignRight: false },
  { id: 'datePay', label: 'Fecha-Pago', alignRight: false },
  { id: 'amount', label: 'Monto', alignRight: false },
  { id: 'datePayOk', label: 'Pago el:', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.coutas.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const curentDate = new Date();
const currentTimestamp = Math.floor(curentDate.getTime() / 1000);

export default function CreditDetail() {
  const navigate = useNavigate();
  const currentUser = useSelector(state => state.currentUser);
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterCuotas, setFilterCuotas] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(12);

  const { state } = useLocation();

  const [credit, setCredit] = useState(null);

  const [openEdit, setOpenEdit] = useState(null);

  const [qtyPending, setQtyPending] = useState(0);

  const [openModal, setOpenModal] = useState(false);

  const [daysDue, setDaysDue] = useState(0);

  const [newAmount, setNewAmount] = useState(0);

  const [newQty, setNewQty] = useState(0);




  useEffect(() => {
    if (state) {
      const qtyPaysPending = state.payments.reduce((acc, payment) => !payment.status && (acc + 1), 0);
      setQtyPending(qtyPaysPending);
      setCredit(state);
      checkDaysDue(state);
    }
  }, [state])

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = LISTCUOTAS.map((n) => n.cuotas);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (e, i, cuota) => {
    const editCredit = JSON.parse(JSON.stringify(credit));
    editCredit.payments[i].status = e.target.checked;
    const dateSelected = new Date();
    editCredit.lastPayDate = dateSelected.toLocaleDateString('es-AR', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const unixTimestamp = Math.floor(dateSelected.getTime() / 1000);
    editCredit.lastPayDateTimestamp = unixTimestamp;
    editCredit.payments[i].datePayOk = unixTimestamp;
    setCredit(editCredit);
  };

  const editAmount = (e, i) => {
    const editCredit = JSON.parse(JSON.stringify(credit));
    editCredit.payments[i].amount = e.target.value;
    const dateSelected = new Date();
    editCredit.lastPayDate = dateSelected.toLocaleDateString('es-AR', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const unixTimestamp = Math.floor(dateSelected.getTime() / 1000);
    editCredit.lastPayDateTimestamp = unixTimestamp
    setCredit(editCredit);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - LISTCUOTAS.length) : 0;

  const filteredUsers = applySortFilter(LISTCUOTAS, getComparator(order, orderBy), filterCuotas);

  const isNotFound = !filteredUsers.length && !!filterCuotas;

  const due = (payments, total) => {
    const paid = payments.reduce((acc, payment) => {
      if (payment.status) {
        acc += +payment.amount.toString().replace(',', '').replace('.', '');
      }
      return acc;
    }, 0)

    return total - paid;
  }

  const save = () => {
    if (due(credit.payments) === 0) {
      credit.status = 'completed';
      credit.cuotasPaid = credit.nroCuotas;
    }
    db.collection('credits').doc(credit.id.toString()).set(credit).then(() => { }).catch((error) => {
      console.error("Error al guardar credito: ", error);
    });
  }

  const handleClose = () => setOpenModal(false);

  const checkDaysDue = (credit) => {
    const whenShouldToBeLastPay = credit.payments.findLast(item => item.status === true);
    // restarle al tiempo actual el tiempo del ultimo pago.
    if (whenShouldToBeLastPay === undefined || credit.nroCuotas === credit.cuotasPaid) {
      return 0
    }
    const diffTime = (currentTimestamp - whenShouldToBeLastPay.datePay);

    // lo pasamos a dias
    const qtyDays = Math.round(diffTime / 86400);

    if (qtyDays >= 2) setDaysDue(qtyDays);

    return qtyDays;
  }

  if (!credit) {
    return <></>
  }

  const { name, amount, amountDiary, payments, date, endFormat } = credit;

  const handleDelete = () => {
    db.collection('credits')
      .doc(credit.id)
      .set({
        ...credit,
        status: 'archived'
      })
      .then(() => {
        navigate(`/dashboard/credits`)
      })
      .catch((error) => {
      });
  }

  const handleEditCredit = () => {
    if (newAmount && newQty) {
      const newPayments = editPayments(credit.payments, newAmount, newQty);
      db.collection('credits')
        .doc(credit.id)
        .set({
          ...credit,
          payments: newPayments
        })
        .then(() => {
          handleClose();
          navigate(`/dashboard/credits`)
        })
        .catch((error) => {
        });
    }
  }

  const editPayments = (payments, newAmount, newQty) => {
    let lastPay = null;
    let qty = 0;
    payments.forEach((payment => {
      if (!payment.status) {
        payment.amount = newAmount;
        lastPay = payment;
        qty += 1;
      }
    }))

    let total = newQty - qty;

    for (let i = 1; i < total; i + 1) {
      // add day to timestamp.
      const dayInSeconds = (60 * 60 * 24) * i;
      const newTimestamp = lastPay.datePay + parseInt(dayInSeconds, 10);
      const fechaDate = new Date(newTimestamp * 1000);

      if (fechaDate.getDay() === 0 && i > 0) {
        total += 2;
        i += 1;
      } else {
        payments.push({
          ...lastPay,
          datePayFormat: moment((newTimestamp * 1000)).format("DD-MM-YYYY"),
          datePay: newTimestamp
        });
        i += 1;
      }
    }

    return payments;
  }

  return (
    <>
      <Helmet>
        <title> Credito </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Credito de {name}
          </Typography>
        </Stack>

        {currentUser?.user?.role === 'admin' && (
          <Card sx={{ display: 'inline-block', height: '250px', width: '350px' }}>
            <Typography sx={{ fontSize: '25px', textAlign: 'center', marginTop: '10px' }} color="text.secondary">
              Detalle del credito
            </Typography>
            <Typography sx={{ fontSize: '18px', marginLeft: '20px' }}>Creado el: {date}</Typography>
            <Typography sx={{ fontSize: '18px', marginLeft: '20px' }}>Monto: $ {amount}</Typography>
            <Typography sx={{ fontSize: '18px', marginLeft: '20px' }}>Cuotas pendiente: {qtyPending}</Typography>
            <Typography sx={{ fontSize: '18px', marginLeft: '20px' }}>Total de cuotas: {credit.nroCuotas}</Typography>
            <Typography sx={{ fontSize: '18px', marginLeft: '20px' }}>Valor: $ {amountDiary}</Typography>
            <Typography sx={{ fontSize: '18px', marginLeft: '20px' }}>Finaliza el: {endFormat}</Typography>
            {/* <Typography sx={{ fontSize: '15px', marginLeft: '20px', marginTop: '4px', backgroundColor: '#ffee58', width: '100px', height: '25px', textAlign: 'center', borderRadius: '10p' }}>Debe: $4000</Typography> */}
            <Button
              type="submit"
              sx={{
                backgroundColor: 'red',
                color: 'white',
                marginRight: '20px',
                marginLeft: '20px',
                marginTop: '15px',
                ':hover': {
                  color: 'black',
                },
              }}
              onClick={() => handleDelete()}

            >
              Eliminar
            </Button>
            <Button
              type="submit"
              sx={{
                backgroundColor: 'green',
                color: 'white',
                marginRight: '5px',
                marginLeft: '5px',
                marginTop: '15px',
                ':hover': {
                  color: 'black',
                },
              }}
              onClick={() => setOpenModal(true)}
            >
              Editar
            </Button>
          </Card>
        )}

        {daysDue > 2 && <Alert variant="filled" severity="error">Atención debe cuotas!</Alert>}
        {credit.nroCuotas === credit.cuotasPaid && <Alert variant="filled" severity="success">Credito Finalizado</Alert>}

        <Card>
          {/* <UserListToolbar
            numSelected={selected.length}
            filterCuotas={filterCuotas}
            onFilterName={handleFilterByCuotas}
          /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={LISTCUOTAS.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {payments.map((pay, i) => {
                    const { status, amount, datePay, datePayOk } = pay;

                    return (
                      <TableRow hover key={i} tabIndex={-1} role="checkbox">
                        <TableCell padding="checkbox">
                          <Checkbox checked={status} disabled={status} onChange={(event) => handleClick(event, i, pay)} />
                        </TableCell>
                        <TableCell align="left" padding="checkbox">{i + 1}</TableCell>

                        <TableCell align="left" padding="normal">
                          <Moment format="DD/MM/YYYY" unix>
                            {datePay}
                          </Moment></TableCell>

                        <TableCell align="left" padding="checkbox">
                          {!status && <EditTwoToneIcon sx={{ fontSize: '24px', paddingLeft: '3px' }} onClick={() => setOpenEdit(i)} />}
                          {openEdit === i ? (
                            <Input type='text' defaultValue={amount} onChange={(event) => editAmount(event, i)} />
                          ) : amount}

                        </TableCell>

                        <TableCell align="left" padding="checkbox">{datePayOk && moment.unix(datePayOk).format('D/M/YYYY')}</TableCell>

                        <TableCell align="right" padding="checkbox">
                          {/* <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterCuotas}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableRow color="success" sx={{ backgroundColor: '#e0f2f1' }}>
                  <TableCell align="center" colSpan={2}>
                    <Button
                      onClick={save}
                      type="submit"
                      sx={{
                        backgroundColor: '#43a047',
                        color: 'white',
                        ':hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Guardar
                    </Button>
                  </TableCell>
                  <TableCell align="center" colSpan={2} color="success">
                    Total: $ {amount}
                  </TableCell>
                  <TableCell align="center" colSpan={2} color="success">
                    Falta: $ {due(payments, (+credit.nroCuotas * +amountDiary.replace(',', '')))}
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={LISTCUOTAS.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container >

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Editar
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Eliminar
        </MenuItem>
      </Popover>
      <Dialog open={openModal} onClose={handleClose} maxWidth="lg">
        <DialogTitle sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <Typography variant="h6" style={{ fontSize: '20px' }}>
            Editar
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Input placeholder='Nuevo monto' type="number" onChange={(e) => setNewAmount(e.target.value)} />
          <Input placeholder='Cantidad cuotas' type="number" onChange={(e) => setNewQty(e.target.value)} />
        </DialogContent>
        <DialogActions sx={{ alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
          <Button onClick={handleEditCredit} variant="outlined">
            Guardar
          </Button>
          <Button onClick={handleClose} variant="outlined" color="error">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
