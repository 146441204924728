import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import {
  Grid,
  Container,
  Typography,
  TextField,
  Checkbox,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  Alert,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { signUpWithEmailAndPassword } from '../utils/auth';


// ----------------------------------------------------------------------
const roles = [{
  id: 'collector',
  name: 'Cobrador'
},
{
  id: 'admin',
  name: 'Administrador'
}];

export default function AddUser() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    userList();
  };
  const userList = () => {
    navigate('/dashboard/users')
  }

  const handleSubmit = (userData) => {
    signUpWithEmailAndPassword(userData)
      .then((user) => {
        console.log(user);
        // dispatch(userLoginSuccess(user));
        navigate('/dashboard/users', { replace: true });
      })
      .catch((err) => console.log(err));
  };
  const handleLocation = () => {
    // Lógica para generar ubicación
  };


  return (
    <>
      <Helmet>
        <title>Agregar Usuario</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Para agregar un usuario debes completar los siguientes campos:
        </Typography>
        <Formik
          initialValues={{
            name: '',
            email: '',
            dni: '',
            address: '',
            password: '',
            role: 'collector',
          }}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Field as={TextField} name="name" label="Nombre" fullWidth />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Field as={TextField} name="email" label="Email" fullWidth />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Field as={TextField} name="dni" label="DNI" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <Field as={TextField} name="address" label="Dirección" fullWidth />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Field as={TextField} name="password" type="password" label="Ingrese la contraseña" fullWidth />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="categoria-select">Seleccione un rol: </InputLabel>
                    <Field as={Select} name="role" label="Permisos de" fullWidth>
                      {roles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>{role.name}
                        </MenuItem>
                      ))}
                    </Field>

                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Button type="submit" variant="contained" color="primary" onClick={handleOpen}>
                    Crear
                  </Button>
                  <Grid>
                    <Dialog open={open} onClose={handleClose} maxWidth="lg">
                      <DialogTitle sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                          <CheckCircleOutlineTwoToneIcon color="success" style={{fontSize: '50px', marginLeft: '12px'}}/>
                        </IconButton>
                        <Typography variant="h6" style={{ fontSize: '20px' }}>
                        El usuario fue creado correctamente!
                        </Typography>
                      </DialogTitle>
                      <DialogActions sx={{ alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                        <Button onClick={handleClose} variant="outlined" color="error">
                          Cerrar
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </Grid>

            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
