import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { db } from '../utils/firebase';
// sections
import { AppWidgetSummary } from '../sections/@dashboard/app';



// ----------------------------------------------------------------------

export default function Home() {
  const theme = useTheme();
  const [qtyCredits, setQtyCredits] = useState(null);

  useEffect(() => {
    const obtenerCreditos = async () => {
      const qty = {
        active: 0,
        canceled: 0,
        completed: 0,
        archived: 0
      };

      try {
        await db.collection('credits').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data().status !== null) {
              qty[doc.data().status] += 1;
            }
          });
          setQtyCredits(qty);
        })
          .catch((error) => {
            console.log('Error al obtener un cliente: ', error);
          });
      } catch (error) {
        console.error('Error al obtener los créditos:', error);
      }
    };

    obtenerCreditos();
  }, []);

  const handleFilter = (filter) => { }

  return (
    <>
      <Helmet>
        <title>Detalle de creditos</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Detalle de creditos
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Activos" total={qtyCredits?.active} color="info" icon={'el:ok'} handleFilter={handleFilter} />
          </Grid>


          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Completos" total={qtyCredits?.completed} color="success" icon={'flat-color-icons:ok'} handleFilter={handleFilter} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Cancelados" total={qtyCredits?.canceled} color="error" icon={'flat-color-icons:cancel'} handleFilter={handleFilter} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
