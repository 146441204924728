import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const prestamos = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.fullName(),
  delivery: sample(['25/12', '22/12', '25/09', '23/11', '12/08']),
  numero: sample([12, 108, 22, 78, 45]),
  status: sample(['active', 'banned']),
  amount: sample([500, 7000, 3000, 6000, 5566]),
  coutas: sample(['02/12', '09/12', '05/12', '10/12', '11/12']),
}));

export default prestamos;
