import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import ReactObserver from 'react-event-observer';

const firebaseConfig = {
    apiKey: "AIzaSyDclmtyxr0XwSUU51v-w-CDR3Ayh1JO9Sk",
    authDomain: "prestamos-sj.firebaseapp.com",
    projectId: "prestamos-sj",
    storageBucket: "prestamos-sj.appspot.com",
    messagingSenderId: "457917443541",
    appId: "1:457917443541:web:aba634af9d4cf362c2a15c"
};

firebase.initializeApp(firebaseConfig);

export const myFirebase = firebase;
export const db = myFirebase.firestore();
export const firebaseAuth = firebase.auth();
export const firebaseObserver = ReactObserver();

firebaseAuth.onAuthStateChanged((user) => {
    firebaseObserver.publish("authStateChanged", loggedIn())
});

export function loggedIn() {
    return firebaseAuth.currentUser;
}