import { Helmet } from 'react-helmet-async';
// @mui

import { Formik, Form, Field } from 'formik';
import { Grid, Container, Typography, List, ListItem, ListItemText } from '@mui/material';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { addClient } from '../utils/services';

// ----------------------------------------------------------------------
export default function ClientDetail() {

  const handleSubmit = (data) => {
    try {
      addClient(data)
    } catch(err) {
      console.log(err);
    }
  };
  
  const handleLocation = () => {
    // Lógica para generar ubicación
  };

  return (
    <>
      <Helmet>
        <title>Detalle Clientes</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Detalles del cliente:
        </Typography>
        <Formik
          initialValues={{
            name: '',
            lastName: '',
            dni: '',
            address: '',
            rol: '',
          }}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <List>
                    <ListItem>
                      <ListItemText id="#" primary="Nombre:" />
                      <ListItemText id="#" primary="Maria" />
                    </ListItem>
                    <ListItem>
                      <ListItemText id="#" primary="Apellido:" />
                      <ListItemText id="#" primary="Dominguez" />
                    </ListItem>
                    <ListItem>
                      <ListItemText id="#" primary="Celular:" />
                      <ListItemText id="#" primary="264546456465" />
                    </ListItem>
                    <ListItem>
                      <ListItemText id="#" primary="Direccion:" />
                      <ListItemText id="#" primary="Barrrio conjunto 8, mnz: G, casa: 90" />
                    </ListItem>
                    <ListItem>
                      <ListItemText id="#" primary="Zona:" />
                      <ListItemText id="#" primary="Rivadavia" />
                    </ListItem>
                    <ListItem>
                      <ListItemText id="#" primary="Rubro:" />
                      <ListItemText id="#" primary="Kiosco" />
                    </ListItem>
                    <Grid item xs={6}>
                      <ListItem>
                        <ListItemText id="#" primary="Estado:" />
                        <CheckCircleOutlineTwoToneIcon color="success" justifyContent="center" alignItems="center" size="20" />
                      </ListItem>
                    </Grid>
                  </List>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
